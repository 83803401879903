.herbal-list-filter {
    @media (min-width: 769px) {
        padding-bottom: 11px;
        margin-bottom: 70px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            border-bottom: 1px solid rgba(32,32,32,0.2);
            left: 100px;
            right: 100px;
            bottom: 10px;
        }
    }
    padding-left: 100px;
    padding-right: 100px;
    .row {
        justify-content: space-between;
        align-items: center;
        @media (max-width: 992px){
            .ms-cms-sort-list {
                display: none;
            }
        }
    }
    @media (max-width: 992px){
        padding-left: 15px;
        padding-right: 15px;
    }
    .ms-cms-sort-list__form  {
        .ms-cms-sort-list__form-item-label {
            font-size: 15px;
            line-height: 20px;
            color: #29292966;
            margin: 0;
        }
        .ms-cms-sort-list__form-item-input {
            border: none;
            color: #231F20;
            font-size: 15px;
            line-height: 20px;
            background-color: transparent;
        }
    }
}
.herbal-list-content {
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 150px;
    @media (max-width: 992px){
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 80px;
    }
    .ms-cms-list-wrapper {
        margin: 0 !important;
    }
    .ms-cms-list--list-video {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        gap: 60px 20px;
        // @media (min-width: 1440px){
        //     gap: 20px;
        // }
    }
    .ms-content-cms__list-row {
        display: flex;
        align-self: flex-start;
        flex-direction: column;
        width: 100%;
        max-width: 395px;
        .item-video {
            &--video-wrapper {
                width: 100%;
                position: relative;
                padding-top: 60%;
                iframe,a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            &--type {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.15em;
                color: #292929;
                padding: 30px 0;
                margin: 0;
            }
            &--title {
                font-size: 24px;
                line-height: 31px;
                letter-spacing: -0.03em;
                color: #292929;
                margin: 0;
                a {
                    color: #292929;
                    &:hover {
                        color: #292929;
                    }
                }
            }
        }
    }

    .ms-nav {
        padding-right: 20px;
        @media (min-width: 1200px){
            max-width: 288px;
        }
        &__list__item {
            + li {
                margin-top: 15px;
            }
            a {
                font-size: 15px;
                line-height: 20px;
                color: rgba(41, 41, 41, 0.4);
                &.active {
                    color: #231F20;
                }
                &:hover {
                    text-decoration: none;
                    color: #231F20;
                }
            }
        }
    }

    .ms-content-cms__table-row {
        tr {
            background-color: transparent;
        }
        td {
            font-size: 15px;
            line-height: 20px;
            color: #292929;
            border: none;
            border-top: 1px solid rgba(32, 32, 32, 0.2);
            white-space: nowrap;
            padding: 0 0 15px;
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: transparent;
            width: 25%;
            + td {
                padding-left: 5px;
                @media (max-width: 769px) {
                    padding-left: 0;
                }
            }
            &:nth-child(1){
                width: auto;
            }
            span {
                padding: 18px;
                display: block;
            }
            &:nth-child(3){
                span{
                    font-style: italic;
                    width: 80%;
                    display: block;
                    white-space: break-spaces;
                }
            }
        }
        .col-code {
            span {
                color: #292929;
                font-weight: 500;
                font-family: 'Test Domaine Sans Text';
                font-size: 16px;
                line-height: 25px;
                max-width: 70px;
                background-color: #EAB4C3;
                @media (max-width: 769px) {
                    max-width: 60px;
                    padding: 14px;
                }
                &.charcoal-color{
                    background-color: #292929;
                    color: #fff;
                }
                &.rose-color {
                    background-color: #DD8797;
                    color: #292929;
                }
                &.berry-color {
                    color: #fff;
                    background-color: #BA5B80;
                }
                &.natural-charcoal-color {
                    background-color: #E3DFD6;
                }
            }
        }
        .col-link1,
        .col-link2 {
            padding-left: 20px;
            a {
                color: inherit;
                &::after {
                    content: '';
                    display: inline-flex;
                    width: 16px;
                    height: 16px;
                    background-image: url('https://files-ap-prod.cms.commerce.dynamics.com/cms/api/mztvwtszhp/binary/MB3bI6');
                    background-repeat: no-repeat;
                    margin-left: 15px;
                    position: relative;
                    top: 2px;
                }
                @media (max-width: 769px) {
                    font-size: 0;
                    &::after {
                        margin-left: 0px;
                        top: 0;
                    }
                }
            }

        }
        .col-add-library {
            text-align: right;
            button {
                font-size: 15px;
                line-height: 20px;
                color: #292929;
                border: none;
                background: none;
                display: flex;
                align-items: center;
                margin-left: auto;
                @media (max-width: 769px) {
                    font-size: 0;
                }
                &.ms-add-to-library__remove-button{
                    &::after{
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\EE57";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        text-align: center;
                        color: #292929;
                        margin-left: 10px;
                    }
                }
                &.ms-add-to-library__create-button{
                    &::after {
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\EA11";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        text-align: center;
                        color: #292929;
                        margin-left: 10px;
                    }
                }
                &.ms-remove-to-library__remove-button{
                    &:after{
                        font-family: "Remix Icon";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\EE57";
                        font-size: 20px;
                        vertical-align: text-bottom;
                        text-align: center;
                        color: #292929;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .ms-product-specification__table {
        display: block;
        overflow-x: auto;
    }

    .ms-cms-content-pagination {
        padding-top: 85px;

        @media (max-width: 769px) {
            padding-top: 45px;
        }

        ul {
            display: flex;
            justify-content: center;
        }
        .msc-page-item {
            width: 30px;
            height: 30px;
            padding: 0;
            justify-content: center;
            align-items: center;
            display: flex;
            + li {
                margin-left: 10px;
            }

            &.previous,
            &.next {
                width: auto;
            }

            &.disabled {
                display: none;
            }

            .msc-page-link {
                font-family: 'Inter', sans-serif;
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                color: #29292966;
            }
            &.active {
                background-color: #292929;
                .msc-page-link {
                    color: #fff;
                }
            }
        }
    }
}
.herbal-side-categories {
    .ms-main-menu_title {
        font-weight: 500;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(35, 31, 32, 0.15);
        &:after {
            content: '';
            display: inline-block;
            border-right: 2px solid #292929;
            border-bottom: 2px solid #292929;
            width: 12px; height: 12px;
            transform: rotate(-133deg);
            margin-top: 3px;
        }
    }
    .ms-cms-search-keyword__form  {
        position: relative;
        max-width: 288px;
        width: 100%;
        margin: 15px 0;
        padding: 20px;
        border: 1px solid rgba(32, 32, 32, 0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
            border: none;
            background-color: transparent;
            padding: 0;
            width: calc(100% - 20px);
            &:focus-visible {
                outline: none;
            }
        }
        button {
            width: 16px;
            height: 20px;
            border: none;
            position: relative;
            font-size: 0;
            &::before {
              font-family: "Remix Icon";
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                text-rendering: auto;
                line-height: 1;
                content: "";
                text-align: center;
                font-size: 20px;
                vertical-align: middle;
                color: #292929;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.side-menu-item {
    font-size: 15px;
    line-height: 20px;
    color: #231F20;
    a {
        color: inherit;
    }
    &-header {
        font-weight: 500;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(35, 31, 32, 0.15);
        &:after {
            content: '';
            display: inline-block;
            border-right: 2px solid #292929;
            border-bottom: 2px solid #292929;
            width: 12px; height: 12px;
            transform: rotate(-133deg);
            margin-top: 3px;
        }
    }
    &-body {
        li {
            margin-top: 15px;
        }
        a {
            text-decoration: none !important;
            color: rgba(41, 41, 41, 0.4) !important;
            &.active {
                color: #231F20 !important;
            }
            &:hover {
                text-decoration: none;
                color: #231F20;
            }
        }
    }
}

